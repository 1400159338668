import React from "react"
import { StaticQuery, Link, graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import { Row, Col, Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
        faAngleRight
    } from "@fortawesome/pro-solid-svg-icons"

import CrestPlaceholderImg from './CrestPlaceHolderImg'

const getNewsPosts = graphql`
query{
  allContentfulNewsPost(limit: 3,
    sort: { fields: [createdAt], order: DESC}) {
    totalCount
    edges {
      node {
        slug
        title
        createdAt(formatString: "MMMM Do, YYYY")
        childContentfulNewsPostPostExcerptTextNode {
          postExcerpt 
        }
        childContentfulNewsPostPostContentRichTextNode {
          json
        }
        image: featuredImage {
          fluid(maxWidth: 600) {
            src
            srcSet
          }
        }
      }
    }
  }
}
`
const newsCards = () => {
  
  return (
    <StaticQuery
      query={ getNewsPosts }
      render={ data => {
        
       const posts = data.allContentfulNewsPost.edges
       return (
        posts.map(({ node }) => {
          return (
            <>
            <Row>
              {!node.image ? <Col sm={3}><CrestPlaceholderImg /></Col> : (
              <Col sm={3}>
              <Link to={`/news/${node.slug}`}>
              <img style={{
                  width: '100%'
                }}
              src={node.image ? node.image.fluid.src : null } alt={node.title} className="rounded" />
              </Link>
        
          </Col> ) }
          <Col>
          <p className="post-title"><Link to={`/news/${node.slug}`}>{node.title}</Link><br />
          <span className="updated">{node.createdAt}</span></p>
          <p>{node.childContentfulNewsPostPostExcerptTextNode.postExcerpt}</p>
          <div className="text-right"><Link to={`/news/${node.slug}`}>Read More <span>
          <FontAwesomeIcon
              icon={faAngleRight}
              style={{
                  fontSize: '1rem',
                  marginRight: '5px',
              }}
                    aria-hidden="true"
              />
            </span></Link></div>

          </Col>
            </Row>
            <hr />
            </>
          )
        })
      )  
      }}
      >

      </StaticQuery>
  )
}

export default newsCards

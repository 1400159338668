import React from 'react'

const HomePageLinks = () => (
    <div className="my-4">
    <h1>Links</h1>
    <div>
        <a href="https://www.sublant.usff.navy.mil/SSN799/" target="_blank" rel="noopener noreferrer">US Navy, PCU IDAHO SSN 799</a>
    </div>
    <div>
        <a href="https://www.navy.mil/" target="_blank" rel="noopener noreferrer">US Navy</a>
    </div>
    <div>
        <a href="https://www.sublant.usff.navy.mil/" target="_blank" rel="noopener noreferrer">US Navy, Commander, Submarine Force Atlantic</a>
    </div>
    <div>
        <a href="https://www.csp.navy.mil/" target="_blank" rel="noopener noreferrer">US Navy, Commander, Submarine Force Pacific</a>
    </div>
    <div>
        <a href="https://www.youtube.com/watch?v=DpIR1mcvi-s" target="_blank" rel="noopener noreferrer">US Navy: Life on a Submarine</a>
    </div>
    <div>
        <a href="http://www.youtube.com/watch?v=vESOqiSWEq4" target="_blank" rel="noopener noreferrer">US Navy: Submarine Force – Silent Service</a>
    </div>
    <div>
        <a href="http://www.gdeb.com/" target="_blank" rel="noopener noreferrer">General Dynamics Electric Boat</a>
    </div>
    <div>
        <a href="http://www.ussidaho.com/" target="_blank" rel="noopener noreferrer">USS IDAHO BB42 Memorial Website</a>
    </div>
    <div>
        <a href="http://www.ss563.org/ussvi/hawkbill.html" target="_blank" rel="noopener noreferrer">IDAHO Submarine Veterans (Hawkbill Base)</a>
    </div>


    </div>
)

export default HomePageLinks
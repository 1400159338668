import React from 'react'
import { Link } from 'gatsby'

const HomePageMoreInfo = () => (
    <div className="my-4">
    <h1>More Info</h1>
    <div>
        <Link to="/about-us#endorsements">Endorsement</Link>
    </div>
    {/* <div>
        <a href="">Photo Gallery</a>
    </div> */}
    <div>
        <Link to="/history#idaho-naval-history">IDAHO Navy</Link>
    </div>
    </div>
)

export default HomePageMoreInfo
import React from "react"
import BackgroundSlider from "gatsby-image-background-slider"
import { useStaticQuery, graphql } from "gatsby"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

const buttonStyle = {
  backgroundColor: "#EDC02B",
  borderColor: "#EDC02B",
  borderRadius: "50px",
  fontFamily: "allotrope, sans-serif",
  fontWeight: 700,
  fontSize: "1rem",
  paddingTop: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
}

const BackgroundSliderComponent = () => {
  return (
    <div style={{ width: "100%", 
    }}>
      <div
        style={{
          color: "white",
          textAlign: "center",
          backgroundColor: "#3E597F",
          width: "100%",
          padding: "2%",
        }}
      >
        <div style={{}}>
          <h1 style={{ color: "white" }}>
          The future USS IDAHO SSN 799 Christening was held in Groton, Connecticut on Saturday, March 16, 2024.
          </h1>

          
          <div className="mb-0">
          <h2 style={{color: '#EDC02B', fontWeight: 500, fontSize:'1.5rem', marginBottom: 0}}>
          Our <Link to="events/pcu-idaho-christening-march-16-2024" style={{color: 'white'}}>past events</Link> page has pictures of the incredible event! Check out the <Link to="https://assets.ctfassets.net/3y19xtrybxm5/1Q5MOpRX3XaCxBhpOBOjhh/2538018c70d2b22315f2fe2fbdf2df0d/USSidahoNewsletter-Issue18-Christening-2024-05-14-Website.pdf" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>latest newsletter</Link> to read more!
          </h2>
          </div>
        </div>
      </div>
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "images" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
        initDelay={12}
        transition={4}
        duration={10}
        images={["sub-1.jpg", "sub-2.jpg", "sub-3.jpg"]}
        style={{
          overflow: "hidden",
        }}
      />
    </div>
  )
}

export default BackgroundSliderComponent

import React from 'react'
import { graphql, Link } from 'gatsby'
import SupportSailorsImg from "../../images/support-sailors.jpg"
import * as styles from "./homepage.module.css"

import { Card, Button } from 'react-bootstrap'

const SupportSailors = () => (
    <>
        <Card className={`text-white ${styles.cardBox}`}>
            <Card.Img src={SupportSailorsImg} alt="Support Our Ship and Sailors" />
            <Card.ImgOverlay>
    <Card.Title className={`text-right ${styles.supportTitle}`}>Support Our Ship and Sailors</Card.Title>
    <div className="text-right"><Button className="ussIdahoButton"><Link to="/donate">Donate Now</Link></Button></div>
  </Card.ImgOverlay>
        </Card>
    </>
)

export default SupportSailors;
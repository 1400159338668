import React from 'react'
import { Link } from "gatsby"
import EventsCards from './eventCards'

const NewsBlock = () => (
<>
<Link to="/events">
    <h1 className="mt-4">USS Idaho Events</h1>
</Link>
<hr />
    <EventsCards />
    </>
)

export default NewsBlock
import React from 'react'
import { Link } from "gatsby"
import NewsCards from './newsCards'

const NewsBlock = () => (
<>
<Link to="/news">
    <h1 className="mt-4">USS IDAHO News</h1>
    </Link>
    <hr />
<NewsCards />
    </>
)

export default NewsBlock
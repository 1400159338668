import React from 'react'
import { Link } from 'gatsby'
import * as styles from './homepage.module.css'
import { CardDeck, Card, Button } from 'react-bootstrap'
import IdahoNavalHistory from '../../images/naval-history.jpg'
import GemOfTheFleet from '../../images/gem-of-the-fleet.jpg'
import SealsFrontpage from '../../images/seals-frontpage.jpg'

const HomePageCards = () => (
    <CardDeck className="text-center">
        <Card className={styles.cardBox}>
        <Card.Img variant="top" src={IdahoNavalHistory} />
          <Card.Body>
            <Card.Title>
              Idaho Naval History
            </Card.Title>
            <Card.Text>
            The 43rd State has an amazing Naval History that even many of our residents are not aware of. From the second largest of seven Navy Bootcamps in WWII to the first Nuclear Submarine Prototype ever built to "The U.S. Submarine force's most important body of water."
            </Card.Text>
          </Card.Body>
          <Card.Footer className={styles.cardBoxFooter}>
              <Button className="ussIdahoButton"><Link to="/history#idaho-naval-history">Learn More</Link></Button>
            </Card.Footer>
        </Card>

        <Card className={styles.cardBox}>
        <Card.Img variant="top" src={GemOfTheFleet} />
          <Card.Body>
            <Card.Title>
            The Future USS IDAHO SSN 799<br />Gem of the Fleet!
            </Card.Title>
            <Card.Text>
            The USS IDAHO SSN 799 will be the 26th Virginia Class Submarine built since the 1999 and the eighth Block IV variant of the class incorporating the very latest in undersea warfighting technology and submarine construction techniques. 
            </Card.Text>
          </Card.Body>
          <Card.Footer className={styles.cardBoxFooter}>
              <Button className="ussIdahoButton"><Link to="/pcu-idaho-submarine">Learn More</Link></Button>
            </Card.Footer>
        </Card>
        <Card className={styles.cardBox}>
        <Card.Img variant="top" src={SealsFrontpage} />
          <Card.Body>
            <Card.Title>
              The Commissioning Committee
            </Card.Title>
            <Card.Text>
            Our Committee responsibilities are numerous. Foremost is building the long-term connection between Idahoans and our namesake warship's young sailors who will serve aboard her in dangerous places.
            </Card.Text>
          </Card.Body>
          <Card.Footer className={styles.cardBoxFooter}>
              <Button className="ussIdahoButton"><Link to="/about-us">Learn More</Link></Button>
            </Card.Footer>
        </Card>
      </CardDeck>
)

export default HomePageCards


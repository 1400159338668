import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundSliderComponent from "../components/BackgroundSlider/BackgroundSliderComponent"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, CardDeck, Card, Button } from "react-bootstrap"

import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import ContentBlock from "../components/ContentBlock/ContentBlock"
import HomePageCards from "../components/HomePage/HomePageCards"
import HomePageLinks from "../components/HomePage/HomePageLinks"
import HomePageMoreInfo from "../components/HomePage/HomePageMoreInfo"
import NewsBlock from "../components/NewsBlock/NewsBlock"
import EventsBlock from "../components/EventsBlock/EventsBlock"
import SupportSailors from "../components/HomePage/SupportSailors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import KeelLayingGallery from "../components/ConstructionPage/KeelLayingGallery"
import PressureHullGallery from "../components/ConstructionPage/PressureHullGallery"

import styled from "styled-components"

const CrestImg = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: { fields },
      },
    }) => (
      <div
        dangerouslySetInnerHTML={{
          __html: `<div style="width: auto; float: right; clear: both; margin-left: 20px; ">
            <img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="margin: 0px" />
            <p style="text-align: center; max-width: 320px;">
              <em>${fields.title["en-US"]}</em>
            </p>
            </div>`,
        }}
      />
    ),

    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const fields = node.data.target.fields.imageLink
      const imgLnk = node.data.target.fields.url
      console.log(fields, imgLnk)
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%" /></a>`,
          }}
        />
      )
    },

    [INLINES.EMBEDDED_ENTRY]: node => {
      const embed = node.data.target.fields.markdownContent
      console.log("embed data: " + embed["en-US"])
      return <div dangerouslySetInnerHTML={{ __html: `${embed["en-US"]}` }} />
    },
  },
}

const IndexPage = ({ data }) => {
  const homeContent = data.homeContent.edges[0].node

  return (
    <Layout>
      <SEO title="Home" />
      <div
        className="#"
        // style={{
        //   position: "relative",
        //   zIndex: "-1",
        // }}
      >
        <div style={{ height: "700px" }}>
          {/* <Img fluid={data.submarine.childImageSharp.fluid} style={{maxHeight: "700px"}} /> */}
          <BackgroundSliderComponent />
        </div>
      </div>
      <Container fluid className="container-fluid-full">
        <ContentBlock>
          <Row className="mb-4">
            <Col md={9}>
              <Row className="mb-4">
                <Col>
                  <p className="homepageCrestPara">
                    <CrestImg>
                      <Img
                        className="homepageCrest"
                        fixed={data.shipsCrest.childImageSharp.fixed}
                        alt="USS Idaho Ship's Crest"
                      />
                    </CrestImg>
                  </p>
                  <h2 className="text-center mb-4">
                    <em>
                      Creating a Strong Bond between a Great State and a Great
                      Ship...
                      <br />
                      Esto Perpetua
                      {/* {homeContent.homeHeadline} */}
                    </em>
                  </h2>
                  {documentToReactComponents(
                    homeContent
                      .childContentfulHomepageContentWelcomeTextRichTextNode
                      .json,
                    options
                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col>
                    <div className="card-box text-center">
                      <h3>The Commissioning Committee</h3>
                      <p style={{
                        fontSize: 'larger'
                      }}>Our Committee responsibilities are numerous. Foremost is building the long-term connection between Idahoans and our namesake warship's young sailors who will serve aboard her in dangerous places.</p>
                      <Row>
                    Centers and prevents button from displaying block
                    <Col className="align-items-center d-flex flex-column">
                    <div>
                  <Button className="ussIdahoButton btn btn-lg mx-auto">
                  <Link to="/about-us" style={{
                    color: 'white'
                  }}>Learn More
                  <span> <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                        fontSize: '14px',
                        marginLeft: '5px'
                    }}
                    aria-hidden="true"
                    
                /></span></Link></Button>
                </div>
                    </Col>
                  </Row>
                    </div>
                    
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <HomePageCards />
                </Col>
              </Row>
              <Row className="my-4">
                <Col sm={6}>
                  <NewsBlock />
                </Col>
                <Col sm={6}>
                  <EventsBlock />
                </Col>
              </Row>
              <Row className="my-4">
                <Col>
                  <h1>
                    Pressure Hull Complete
                    <br />
                    Fall 2023
                  </h1>
                  <PressureHullGallery />
                </Col>
              </Row>
              <Row className="my-4">
                <Col>
                  <h1>
                    Keel Laying Ceremony
                    <br />
                    24 AUG 2020
                  </h1>
                  <KeelLayingGallery />
                  {/* <FollowUs /> */}
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <div style={{ marginBottom: "2rem", marginTop: "1rem" }}>
              <div className="mb-2">
                  <div className="">
                    <h2>IDAHO 799 Float Off</h2>
                  </div>
                  <iframe
                    src="https://player.vimeo.com/video/1018651614"
                    width="100%"
                    height="200"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="px-2">
                    <p className="small">
                    See this incredible footage of the Float Off (Launch) of the IDAHO 799 at Electric Boat in Groton on 06 AUG 2024.
                    </p>
                  </div>
                </div>
              <div className="mb-2">
                  <div className="">
                    <h2>IDAHO 799 Roll out</h2>
                  </div>
                  <iframe
                    src="https://player.vimeo.com/video/1018650695"
                    width="100%"
                    height="200"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="px-2">
                    <p className="small">
                    See this amazing time-lapse footage of the IDAHO 799 being rolled out of the manufacturing building at Electric Boat in Groton and being staged for the Float Off.
                    </p>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="">
                    <h2>USS IDAHO March</h2>
                    <p>Silver and Gold Beneath the Waves</p>
                  </div>
                  <iframe
                    src="https://player.vimeo.com/video/673920935"
                    width="100%"
                    height="200"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="px-2">
                    <p className="small">
                      A march dedicated to the sailors of the USS IDAHO SSN 799
                      serving our nation and representing the people of the
                      great state of Idaho
                      <br />
                      <strong>Composer:</strong> Professor Dan Bukvich,
                      University of Idaho Lionel Hampton School of Music
                    </p>
                  </div>
                </div>
                <div className="mb-2">
                  <iframe
                    src="https://player.vimeo.com/video/673922333?h=6742f39fa7"
                    width="100%"
                    height="200"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <p className="small">
                    The complete breakdown of the different parts of{" "}
                    <em>Silver and Gold Beneath the Waves</em> by Professor Dan
                    Bukvich.
                  </p>
                </div>
                <div className="mb-2">
                  <a
                    href="https://assets.ctfassets.net/3y19xtrybxm5/4svLszZflE2gc3rrbz0wOl/39059138b57316dd6d2af643c0df89f6/Silver-and-Gold-Beneath-the-Waves.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download the musical score for this march
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      style={{
                        fontSize: "1rem",
                        marginLeft: "5px",
                      }}
                      aria-hidden="true"
                    />
                  </a>
                </div>
              </div>
              <div className="">
                <iframe
                  src="https://player.vimeo.com/video/518626669"
                  width="100%"
                  height="200"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <HomePageMoreInfo />
              <HomePageLinks />

              <div className="mb-4">
                <h2>
                  <span style={{ fontSize: "1rem" }}>
                    IDAHO Public Television
                  </span>
                  <br />
                  IDAHO EXPERIENCE
                  <br />
                  Idaho's Nuclear Navy
                </h2>
                <Link to="/events/idaho-public-television-idaho-experience-idahos-nuclear-navy">
                  <Img
                    fluid={data.nuclearNavy.childImageSharp.fluid}
                    alt="Idaho's Nuclear Navy"
                  />
                </Link>
              </div>

              <div className="mb-4">
                <h2>ARD Bayview Video</h2>
                <iframe
                  style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  width="100%"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/tTc4yHfSNpg"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; 
          picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <SupportSailors />
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <Img
                fluid={data.fullyLoaded.childImageSharp.fluid}
                alt="USS IDAHO fully loaded banner"
              />
            </Col>
          </Row>
        </ContentBlock>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    shipsCrest: file(relativePath: { eq: "USS-Idaho-SSN-799.png" }) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    homeContent: allContentfulHomepageContent {
      edges {
        node {
          homeHeadline
          childContentfulHomepageContentWelcomeTextRichTextNode {
            json
          }
        }
      }
    }
    fullyLoaded: file(relativePath: { eq: "USS-Idaho-Potatoe-banner.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    submarine: file(relativePath: { eq: "sub-1.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nuclearNavy: file(relativePath: { eq: "Idaho-Nuclear-Navy.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
